import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { apiUrl } from '../../config/constants';
import { ConsumptionResponse, DataPoint } from '../../store';
import { Customership, Contract, Customerships } from '../../types/companies';
import { formatConsumption } from './CompanyCard';

const MeteringPoint = ({
  mp,
  startDate,
  endDate,
  company
}: {
  mp: Customership;
  startDate: moment.Moment;
  endDate: moment.Moment;
  company: Customerships;
}): JSX.Element => {
  const [usageSum, setUsageSum] = useState<string>('');
  const [showNoDataMessage, setShowNoDataMessage] = useState<boolean>(false);
  const [unit, setUnit] = useState<string>("Ladataan...")

  useEffect(() => {
    const controller = new AbortController();
    const abortSignal = controller.signal;

    (async () => {
      const res = await getContract(mp, abortSignal);
      if (res.usage) {
        setUsageSum(res.usage.usageSum.toString());
      }
    })()
    return () => controller.abort(); //CHECK is this unbound? -ADP

  }, [startDate, endDate]);

  const getContract = async (contract: Contract, abortSignal: AbortSignal) => {
    const type = (contract.contractType === 'Sähköntuotannon verkkosopimus'
      || contract.contractType === 'Sähköverkkosopimus')
      ? 'electricity' : 'heat';

    const consumptionResponse = await loadData(type, mp.meteringpointIdentifier, abortSignal);

    let sum = 0;
    let timestampPeriod: string[] = [];
    let data: DataPoint[] | undefined;

    if (consumptionResponse && typeof consumptionResponse !== 'boolean') {
      data = consumptionResponse.data;
      setUnit(consumptionResponse.unit)
    }

    if (data) {
      const rawsum = data.map(d => d.v).reduce((a, b) => a + b, 0);

      if (contract.utility === 'water') {
        sum = +rawsum.toFixed(3);
      } else {
        sum = Math.round(rawsum);
      }

      if (data.length === 0) setShowNoDataMessage(true);

      if (data.length > 0) {
        const periodStart = moment(data[0].ts);
        const periodEnd = moment(data[data.length - 1].ts);
        if (data.length === 1) {
          periodEnd.endOf('month');
        }
        timestampPeriod = [
          periodStart.format('DD.MM.'),
          periodEnd.format('DD.MM.')
        ];
      }

      data.forEach((o, i, a) => {
        a[i].ts = moment(a[i].ts).format('DD.MM.');
      });

      contract.usage = {
        data: data,
        period: timestampPeriod,
        periodType: '',
        usageSum: sum
      };
    } else {
      contract.usage = {
        period: timestampPeriod,
        periodType: '',
        usageSum: sum
      };
    }

    return contract;
  };

  // TODO Determine type.
  const loadData = async (
    type: string,
    id: string,
    abortSignal: AbortSignal
  ): Promise<boolean | ConsumptionResponse> => {
    const start = moment(startDate).format('YYYY-MM-DD');
    const end = moment(endDate).format('YYYY-MM-DD');
    const path = 'thisyear';

    const req = new Request(
      `${apiUrl}/${type}/${path}?start=${start}&end=${end}&mp=${id}&companyid=${company.identifier}&logintype=b2b`,
      {
        method: 'GET',
        mode: 'cors'
      }
    );

    let response;
    try {
      response = await fetch(req, { signal: abortSignal });
      if (response.ok) {
        return response.json();
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  return (
    <div>
      <div>
        <p className="meteringpoint-name">
          {`${mp.address}, ${mp.zip} ${mp.city}`}
        </p>
        {
          !mp.gsrnIdentifier
            ? (<p>{`Metering Point ID: ${mp.meteringpointIdentifier}`}</p>)
            : (<p>{`GSRN: ${mp.gsrnIdentifier}`}</p>)
        }
        {company.contracts.length > 0 && (
          <p>
            {moment(startDate).format('D.M.YYYY')} &ndash;{' '}
            {moment(endDate).format('D.M.YYYY')}
            <br />
            {showNoDataMessage ? "Tietoja ei ole saatavilla tälle kohteelle" : `${formatConsumption(usageSum)} ${unit}`}
          </p>
        )}
      </div>
      <Link
        to={
          (mp.contractType === 'Sähköverkkosopimus' || mp.contractType === 'Sähköntuotannon verkkosopimus')
            ? `sahko/${mp.meteringpointIdentifier}/${company.identifier}`
            : `kaukolampo/${mp.meteringpointIdentifier}/${company.identifier}`
        }
        className="consumption"
      >
        Siirry kulutusnäkymään
      </Link>
    </div>
  );
};

export default MeteringPoint;
